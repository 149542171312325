import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

function HorizontalLoader() {
  return (
    <Stack direction="row" columnGap={{ xs: 0.5, md: 2 }} mt={2} width="100%">
      <Skeleton width="100%" style={{height:"40px"}} />
      <Skeleton width="100%" style={{height:"40px"}} />
      <Skeleton width="100%" style={{height:"40px"}} />
      <Skeleton width="100%" style={{height:"40px"}} />
      <Skeleton width="100%" style={{height:"40px"}} />
      <Skeleton width="100%" style={{height:"40px"}} />
    </Stack>
  );
}

export default HorizontalLoader;
